@import "~bootstrap/scss/bootstrap";

body {
    background: #eee;
}
.container {
    max-width: 600px;
    margin-top: 50px;
}

.sr-only {
    display: none;
}

.sp-logo {
    margin-bottom: 2em;
    text-align: center;
}
.sp-logo img {
    max-width: 90%;
}
.sso-form {
    background: #fff;
}

.login-grid {
    @extend .mb-3;

    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;
    align-items: center;
    // Grow to 2 columns at medium and wider
    @include media-breakpoint-up("md") {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 2rem;
    }
    & > * {
        @extend .m-0;
    }

    & > hr {
        grid-column: 1 / span 1;
        @include media-breakpoint-up("md") {
            grid-column: 1 / span 2;
        }
    }

    & > .descr {
        grid-column: 1 / span 1;
    }

    & > .link {
        grid-column: 1 / span 1;
        text-align: center;
        margin-bottom: 1rem;
        @include media-breakpoint-up("md") {
            grid-column: 2 / span 1;
            // When in 2-column mode, make the buttons right aligned
            text-align: right;
            margin-bottom: 0;
        }
    }
}

#webauthn-add-token,
#webauthn-verify-token,
#totp-add-token,
#totp-verify-token {
    text-align: center;
    .totp-qr-code {
        max-width: 200px;
    }
    .spinner-border {
        margin: 1em;
    }
    .waiting-for-user-action--hidden,
    .waiting-for-key--hidden,
    .name-key--hidden {
        display: none;
    }
    .alternate-mfa-method {
        margin-top: 2em;
    }
}
